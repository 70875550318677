
import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { from, Observable, throwError, EMPTY } from 'rxjs';
import { catchError, mergeMap, timeout } from 'rxjs/operators';
import { OktaService } from './okta.service';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';


@Injectable()
export class OktaInterceptor implements HttpInterceptor {



  private TIMEOUT_VALUE: number = 300000;

  constructor(private oktaService: OktaService, private msalService: MsalService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const identifiedUser = sessionStorage.getItem('aad_cred');
    const user = this.msalService.instance.getAllAccounts()[0];
    //const backEndScope: string[] = ["api://5af1159a-7066-4d92-b56c-48ab654592e1/User.Read"];

    const requester = {
      scopes: environment.backEndScope,
      account: user as any
    };



    return this.msalService.acquireTokenSilent(requester).pipe(mergeMap((token: any) => {

      const accessToken = token.accessToken;
      let authorizedRequest = request.clone({});

      if (!request.url.toString().includes(environment.ExcludeFromInterceptors)) {
        authorizedRequest = request.clone({
          setHeaders: { 'Authorization': `Bearer ${token.accessToken}` }
        });
      }

      return next.handle(authorizedRequest).pipe(
        timeout(this.TIMEOUT_VALUE),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {

            if (error.status === 404 && !error.url?.includes('Document')) {
              //this.oktaService.loginUsingIdp();
              this.router.navigate(['/not-found']);
            }
            if (error.status === 401) {
              console.error(error)
              // this.oktaService.loginUsingIdp();
              this.router.navigate(['/misc/unauthorized']);
            }
            return throwError(error);
          }

          return throwError(errorMessage);
        })
      );
    }),
      catchError((error: HttpErrorResponse) => {
        console.log(error, 'Interceptor');
        return EMPTY;
      }))


  }
}
